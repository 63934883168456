var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "pa-2 mb-15",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-list', {
    attrs: {
      "vertical": ""
    }
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('v-list-item', {
      key: index,
      class: index == _vm.tab ? _vm.$vuetify.theme.dark ? 'lightBackground' : 'grey' : '',
      attrs: {
        "cols": "12"
      },
      on: {
        "click": function click($event) {
          _vm.tab = item.value;
        }
      }
    }, [item.tag ? _c('div', [_vm._v(" " + _vm._s(item.title) + " "), _c('v-chip', {
      staticClass: "mx-5",
      attrs: {
        "color": "success",
        "x-small": "",
        "label": ""
      }
    }, [_vm._v(_vm._s(item.tag))])], 1) : _c('div', [_vm._v(" " + _vm._s(item.title) + " ")])]);
  }), 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "outlined": _vm.tab !== 2,
      "flat": _vm.tab == 2
    }
  }, [_c(_vm.menuList[_vm.tab].component, {
    tag: "component"
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }