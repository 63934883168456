<template>
  <v-row class="mt-4">
    <v-col cols="12" md="3">
      <v-card class="pa-2 mb-15" outlined>
        <v-list vertical>
          <v-list-item
            :class="
              index == tab
                ? $vuetify.theme.dark
                  ? 'lightBackground'
                  : 'grey'
                : ''
            "
            cols="12"
            v-for="(item, index) in menuList"
            @click="tab = item.value"
            :key="index"
          >
            <div v-if="item.tag">
              {{ item.title }}
              <v-chip class="mx-5" color="success" x-small label>{{
                item.tag
              }}</v-chip>
            </div>
            <div v-else>
              {{ item.title }}
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="12" md="9">
      <v-card :outlined="tab !== 2" class="pa-3" :flat="tab == 2">
        <component :is="menuList[tab].component"></component>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    UserProfile: () => import("@/view/components/User/EditProfile.vue"),
    NotificationSettings: () =>
      import("@/view/components/User/Notification/ManageSettings.vue"),
    ApiTokens: () => import("@/view/pages/user/settings/api-tokens.vue"),
  },
  TABS: {
    "profile-settings": 0,
    "user-notification-settings": 1,
    "api-tokens": 2,
  },
  data() {
    return {
      menuList: [
        {
          title: "Profile",
          component: "user-profile",
          value: 0,
        },
        {
          title: "Notifications",
          component: "notification-settings",
          tag: "",
          value: 1,
        },
        {
          title: "API Tokens",
          component: "api-tokens",
          value: 2,
        },
      ],
      tab: 0,
    };
  },
  watch: {
    tab(tab) {
      if (
        this.$route.name.includes(Object.keys(this.$options.TABS)[tab || 0])
      ) {
        return;
      }
      this.$router
        .push({
          name: Object.keys(this.$options.TABS)[tab || 0],
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message ||
              "Something went wrong, please reload your page and try again"
          );
        });
    },
  },
  created() {
    const activeTab = this.$route.name;
    this.tab = this.$options.TABS[activeTab] || 0;
  },
};
</script>
